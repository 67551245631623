import './App.css';
import React, { useState } from 'react';

function App() {
  
  let [num, setNum] = useState(0);

 let handleClick1 = () => setNum(num + 1);
 
function handleClick2() {
  if (num > 0){
    setNum(num - 1)
  }

}
const myStyle={
  backgroundImage: `url(${process.env.PUBLIC_URL}/Images/backgnd01.png)`,
  height:'100vh',
  marginTop:'0px',
  fontSize:'20px',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

  return (
    <div className="App" style={myStyle}>
      <h1>Hello, world !</h1>
      <div><h2>{num}</h2></div>
      <button onClick={handleClick1}> + </button>
      <button onClick={handleClick2}> - </button>

    </div>
  );
}

export default App;
